<template>
    <div class="grid">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#173EA3"
      :is-full-page="fullPage"
    ></loading>
        <div class="col-12">
            <div class="card">
                <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
                <h5>{{show_name}} Product</h5>
                <hr>
                <div class="p-fluid formgrid grid">                    

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="title" type="text" v-model="product.title"
                                :class="{ 'p-invalid': submitted && !product.title }" />
                            <label for="title">Title</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="type" type="text" v-model="product.type"
                                :class="{ 'p-invalid': submitted && !product.type }" />
                            <label for="type">Type</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="sub_type" type="text" v-model="product.product_type_details"
                                :class="{ 'p-invalid': submitted && !product.product_type_details }" />
                            <label for="sub_type">Sub Type</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="brand" type="text" v-model="product.brand"
                                :class="{ 'p-invalid': submitted && !product.brand }" />
                            <label for="brand">Brand</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText type="number" id="actual_price" v-model="product.actual_price"
                                :class="{ 'p-invalid': submitted && !product.actual_price }" />
                            <label for="actual_price">Actual Price</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText type="number" id="price" v-model="product.price"
                                :class="{ 'p-invalid': submitted && !product.price }" />
                            <label for="price">Price</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="site" type="text" v-model="product.site"
                                :class="{ 'p-invalid': submitted && !product.site }" />
                            <label for="site">Site</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="url" type="text" v-model="product.url"
                                :class="{ 'p-invalid': submitted && !product.url }" />
                            <label for="url">URL</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="model" type="text" v-model="product.model"
                                :class="{ 'p-invalid': submitted && !product.model }" />
                            <label for="model">Model</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="discount" type="text" v-model="product.discount"
                                :class="{ 'p-invalid': submitted && !product.discount }" />
                            <label for="discount">discount</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-12" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="main_img_url" type="text" v-model="product.image"
                                :class="{ 'p-invalid': submitted && !product.image }" />
                            <label for="main_img_url">Main Image URL</label>
                        </span>
                    </div>
             
                    <div class="field col-12 md:col-8 my-3">
                        <span class="p-float-label">
                            <InputText id="main_img_url2" type="text" v-model="newImageUrl" />
                            <label for="main_img_url2">Thumbnail Image URL</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-4 my-2">
                        <Button label="add" style="width: 100%;" class="p-button-primary my-2" @click="addImage" />                        
                    </div>

                    <div class="image-list col-12 md:col-12">
                        <table class="image-table">
                            <tbody >
                            <tr v-for="(image, index) in product.images" :key="index">
                                <!-- <td><img :src="image" alt="Additional Image" height="100" width="100"></td>  -->
                                <td>{{ image }}</td> 
                                <!-- <td><button @click="removeImage(index)">Remove</button></td> -->
                                <td>
                                    <button @click="removeImage(index)" style="text-align: right;" class="p-button p-component p-button-icon-only p-button-rounded p-button-danger p-button-outlined mr-2 mb-2"  type="button"   >
                                        <span class="p-button-icon pi pi-times" data-pc-section="icon"></span>                                    
                                        <span role="presentation" aria-hidden="true" data-p-ink="true" data-p-ink-active="false" class="p-ink" data-pc-name="ripple" data-pc-section="root"> </span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>                        
                    </div>

                    <div class="field col-12 md:col-12">
                        <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
                        Description :
                        </h6>
                        <Editor v-model="description" editorStyle="height: 200px" />
                    </div>

                    <!-- <div class="field col-12 md:col-3" style="margin-top: 1%;">
                        <label for="menu_url">Image</label> 
                            <FileUpload
                              class="p-button-primary"
                              mode="basic"
                              name="model[]"
                              :auto="true"
                              :custom-upload="true"
                              choose-label="Chose"
                              accept="image/*"
                              @uploader="handleFileUpload($event)"
                              style="
                                box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                                border-color: #00b0b0 !important;
                                background-color: #00b0b0 !important;
                              "
                            />
                    </div>
                    <div class="field col-12 md:col-3" style="margin-top: 1%;">
                        <img 
                            :src="product.image" 
                            style="max-width: 50%; 
                            max-height: 200px; 
                            margin-top: 10px; 
                            border: 2px solid #D3D3D3;"
                        >
                    </div> -->
                    

                    <!-- <div class="field col-12" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <Textarea id="description" rows="4" v-model="product.description"
                                :class="{ 'p-invalid': submitted && !product.description }" />
                            <label for="description">description</label>
                        </span>
                    </div> -->
                <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Calendar
                       
                        v-model="product.dob"
                        dateFormat="dd-mm-yy"
                        :showIcon="true"
                        :showTime="false"
                        hourFormat="12"
                        />
                        <label for="dob">Birth Date</label>
                    </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                       <Dropdown id="department" v-model="department" :options="departments"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !department }"></Dropdown>
                            <label for="department">Department</label>
                            </span>
                    </div>

                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="state" v-model="state_dropdownItem" :options="state_dropdownItems" optionLabel="name" filter
                             :class="{ 'p-invalid': submitted && !state_dropdownItem }">
                        </Dropdown>
                        <label for="state">State</label>
                        </span>
                    </div>
                    </div> -->
                    <div class="field col-12 md:col-4" style="margin-top: 1%;"></div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button v-if="product._id" label="Update" class="p-button-success mr-2" @click="Add_user" />
                        <Button v-else label="Add" class="p-button-success mr-2" @click="Add_user" />
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button label="Reset" class="p-button-danger mr-2" @click="reset_user" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import InputValidation from '../service/InputValidation';
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
export default {
    data() {
        return {
            departments:[],
            department:null,
            location_dropdown: [],
            location_search:'',
            place_details:'',
            client_id:'',
            user_type:'',
            id: "",
            list: [],
           
            status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                { name: "Deleted", value: "Deleted" },
            ],
            status_dropdownItem: null,

            gender_dropdownItems: [
                { name: 'Male', value: 'Male' },
                { name: 'Female', value: 'Female' },
                { name: 'Other', value: 'Other' }
            ],           
            show_name:"Add",

            product: {},
            submitted: false,
            BreadcrumbService : null,
            breadcrumbHome: {},
            breadcrumbItems: [],
            newImageUrl: ''
        }

    },
    productService: null,
    methods: {
        addImage() {
            if (this.newImageUrl.trim() !== '') {
                this.product.images.push(this.newImageUrl);
                this.newImageUrl = ''; // clear input after adding
            }
        },
        removeImage(index) {
            this.product.images.splice(index, 1);
        },
       dropdown_list(search)
        { 
        var data = { search:search.value};
            var promise = apis.geocodelats(data);
            promise.then((response) => {
            this.location_dropdown=[];
            var temp=response.data.result;
            for (let a = 0; a < temp.length; a++) {
                this.location_dropdown.push( { name: temp[a].Text, value: temp[a].PlaceId });
                
            }
            });
        },
        async handleFileUpload(event) {       
            var fileUp = event.files[0];
            if (fileUp) {
                this.previewImage = URL.createObjectURL(fileUp);
            }
            var file = fileUp;
            this.file = file;
            var prfeix = await this.date_time_prefix();
            var filename = prfeix + "_" + file.name;
            this.file_data = {
                filePath: 'brands/'+filename,
                contentType: file.type,
            };
            return false;
            var promise = apis.upload_to_AWS(this.file_data);
            promise.then((response) => {
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                    this.attachments =
                    "https://pph-docs.s3.ap-south-1.amazonaws.com/" + filename;
                    
                });
            });
        },
        Add_user() {
            this.submitted = true;
            // this.product.location=this.place_details;
           
            // if (this.status_dropdownItem==null || this.status_dropdownItem.value=='' || this.status_dropdownItem.value==undefined ||  this.status_dropdownItem.value==[]) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Select Status",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (this.user_type_dropdownItem==null || this.user_type_dropdownItem.value=='' || this.user_type_dropdownItem.value==undefined ||  this.user_type_dropdownItem.value==[]) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Select Product Type",
            //         life: 3000,
            //     });
            //     return false;
            // }
          

            // if (!this.product.first_name) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter First Name",
            //         life: 3000,
            //     });
            //     return false;
            // }

            // if (!this.product.last_name) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Last Name",
            //         life: 3000,
            //     });
            //     return false;
            // }
           
            // if (!this.product.email_id) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Email ID",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            // if(this.product.email_id)
            // {
            //     if(emailreg.test(this.product.email_id)==false)
            //     {
            //         this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter valid Email ID",
            //         life: 3000,
            //     });
            //       return false;
            //     }
            // }
            // const phoneRegex = /^[0-9]{10}$/;
            // if (!this.product.mobile_number || (this.product.mobile_number && !phoneRegex.test(this.product.mobile_number))) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter valid Mobile No",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.alternate_contact_no || (this.product.alternate_contact_no && !phoneRegex.test(this.product.alternate_contact_no))) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Alternete Mobile No.",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (this.gender_dropdownItem==null || this.gender_dropdownItem.value=='' || this.gender_dropdownItem.value==undefined ||  this.gender_dropdownItem.value==[]) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Select Gender",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.dob) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Date of Birth",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.address) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Address",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.location) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Location",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (this.state_dropdownItem==null || this.state_dropdownItem.value=='' || this.state_dropdownItem.value==undefined ||  this.state_dropdownItem.value==[]) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Select State",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.city) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter city",
            //         life: 3000,
            //     });
            //     return false;
            // }
            // if (!this.product.pinvalue) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Pin code",
            //         life: 3000,
            //     });
            //     return false;
            // }

        if (this.product._id) 
            { //edit
                var data1 = {
                "_id":this.product._id,
                "title": this.product.title,
                "type": this.product.type,
                "product_type_details": this.product.product_type_details,
                "brand": this.product.brand,
                "actual_price": this.product.actual_price,
                "price": this.product.price,
                "site": this.product.site,
                "url": this.product.url,
                "model": this.product.model,
                "discount": this.product.discount,
                "updated_at": "",
                "updated_by": this.id,
                // "dob":this.dateFormatYMD(this.product.dob),
               
                };
                console.log("Edited data",data1);
                // this.$router.push({name:"productmaster"});
                return false;
                this.isLoadingModel = true;
                var promise = apis.edituser(data1);
                promise
                    .then((responseapi) => {
                        this.$swal(responseapi.data.message);
                        this.isLoadingModel = false;
                        this.reset_user();
                        this.$router.push({name:"usermaster"});
                    })
                    .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal.fire(error.response.data);
                    });
            }

        },        
        get_user_type(client_id) {
            this.loading = true;
            var data = { _id: client_id };
            var promise = apis.user_rolelist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({ name: response.data.data[x].user_role_name, value: response.data.data[x].user_role_name });

                    }
                    this.user_type_dropdownItems = temp_data;
                }

            });
        },
        reset_user() {
            this.product = {};
            this.submitted = false;
            this.status_dropdownItem = null;
            this.gender_dropdownItem = null;
            this.state_dropdownItem = null;
            this.user_type_dropdownItem = null;
            this.client_dropdownItem = null;
            this.user_dropdownItem = null;
            this.department=null;
            this.show_name="Add";

        },
        dateFormatYMD(inputDate) {
        var postdate=inputDate;
        if(inputDate)
        {
            inputDate=new Date(inputDate);
            if(inputDate.toString()=='NaN-NaN-NaN')
            {
                return postdate;                
            }
            else
            {
                let date = ("0" + inputDate.getDate()).slice(-2);
                let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
                let year = inputDate.getFullYear();
                if(date.toString()=='aN')
                {
                  return postdate;
                }
                else
                {
                  return year+"-"+month+"-"+date;
                }
            }
        }
        else {
           return "";
        }
    },
    },
    async created() {
        this.productService = new ProductService();
        this.InputValidation = new InputValidation();

        this.BreadcrumbService = new BreadcrumbService();
        let Breadcrum_data = this.BreadcrumbService.product_edit()
        this.breadcrumbHome=Breadcrum_data.home;
        this.breadcrumbItems=Breadcrum_data.items;


        this.id = localStorage.getItem('id');
        this.client_id=localStorage.getItem('client_id');
        this.user_type=localStorage.getItem('user_key');
     
       
        if(localStorage.getItem('saveproductdata'))
        {
            this.product=await JSON.parse(localStorage.getItem('saveproductdata'));
            console.log("🚀 ~ file: user_edit.vue:739 ~ created ~  this.product:",  this.product);  
            this.show_name="Update";
        }
      await localStorage.setItem('saveproductdata','');      

    },
    components: { downloadExcel: JsonExcel },
}
</script>

<style>
.image-table {
  /* border-collapse: collapse; */
  width: 100%; /* Adjust width as needed */
  padding: 5px;
}

.image-table td {
  border: 1px solid #ccc;
  padding: 5px;
}
</style>
